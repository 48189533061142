import React from 'react';

import { ContentMainHeader } from '../basic/ContentMainHeader';
import { ContentSubHeader } from '../basic/ContentSubHeader';
import { ContentParagraph } from '../basic/ContentParagraph';
import { ContentCaption } from '../basic/ContentCaption';

import { SignupFormWithHelmParams } from './SignupFormWithHelmParams';

export const DifHackathon2024SignupForm = () => (
    <SignupFormWithHelmParams
        isOptionalStayInTouch
        stayInTouchText="Get the latest product updates and news"
        logoUrl={`${process.env.PUBLIC_URL}/dif-logo.png`}
        additionalSignUpFields={[
            {
                type: 'hidden',
                name: 'dif_hackathon_2024',
                value: 'true',
            },
        ]}
    >
        <ContentMainHeader>Truvity Platform Sign-Up for DIF Hackathon Participants</ContentMainHeader>
        <ContentSubHeader>Start Building Today</ContentSubHeader>
        <ContentParagraph>
            Get free early access to the Truvity platform, exclusively for participants of the Decentralized Identity
            Foundation Hackathon 2024. Explore the future of identity management, make instant trust a reality for your
            use case, and simplify document exchange.
        </ContentParagraph>
        <ContentSubHeader>What You&apos;ll Get Access To:</ContentSubHeader>
        <ContentCaption>Self-Sovereign Identity Tools</ContentCaption>
        <ContentParagraph>Access our SSI technology stack and implement verifiable credentials.</ContentParagraph>
        <ContentCaption>Advanced Features</ContentCaption>
        <ContentParagraph>
            Utilize built-in verification mechanisms, data collection/resharing protocols and other advanced features.
        </ContentParagraph>
        <ContentCaption>Developer Resources</ContentCaption>
        <ContentParagraph>
            Leverage extensive documentation and get support from our team and community.
        </ContentParagraph>
    </SignupFormWithHelmParams>
);
