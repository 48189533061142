import React, { useEffect } from 'react';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

import { DifHackathon2024SignupForm } from '../specific/DifHackathon2024SignupForm';

export const DifHackathon2024Page = () => {
    const sendDataToGTM = useGTMDispatch();

    useEffect(() => {
        sendDataToGTM({ event: 'previousUserUrl', value: document.referrer });
    }, [sendDataToGTM]);

    return <DifHackathon2024SignupForm />;
};
