import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { routes } from '../../../../routes';
import { CookieDeclarationPage } from '../../../pages/CookieDeclarationPage';
import { HomePage } from '../../../pages/HomePage';
import { VerifyEmailPage } from '../../../pages/VerifyEmailPage';
import { DifHackathon2024Page } from '../../../pages/DifHackathon2024Page';

export const AppRoutes = () => (
    <Routes>
        <Route path={routes.HOME.path} element={<HomePage />} />
        <Route path={routes.DIF_HACKATHON_2024.path} element={<DifHackathon2024Page />} />
        {/**
         * This `pathname` must be synchronized with the redirect in the `deny-unverified-user` Auth0 action.
         * You can find the action in: `auth0/actions/post-login/deny-unverified-user/code.js`.
         */}
        <Route path={routes.VERIFY_EMAIL.path} element={<VerifyEmailPage />} />
        <Route path={routes.COOKIE_DECLARATION.path} element={<CookieDeclarationPage />} />
        <Route path="*" element={<Navigate to={routes.HOME.path} replace />} />
    </Routes>
);
